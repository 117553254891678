import * as sentry from "@sentry/browser"
import { isDevEnv } from "./index"

export const reportError = (e: Error) => {
  if (isDevEnv) {
    console.warn("error reported", e)
  } else {
    sentry.captureException(e)
  }
}

export const reportMessage = (msg: string, severity?: sentry.Severity) => {
  if (isDevEnv) {
    console.warn("error message reported", msg)
  } else {
    sentry.captureMessage(msg, severity)
  }
}
