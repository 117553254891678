/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { apiClient, isDevEnv } from "../utils"
import Header from "./header"
import Footer from "./footer"
import "../style/style.scss"

interface CloudEnv {
  isDevEnv: boolean
  skipEmail: boolean
  skipLob: boolean
}

const IsDevEnv = () => (
  <div id="is-dev-env">
    <p>Client: Development version</p>
    {process.env.GATSBY_SKIP_PAYMENT === "true" && (
      <small>Skipping PayPal button</small>
    )}
  </div>
)

const IsCloudDevEnv = ({ cloudEnvDev }: { cloudEnvDev: CloudEnv }) => (
  <div id="is-cloud-env-dev">
    <p>Server: Development version </p>
    <p>
      <small>No real postcards etc will be sent</small>
    </p>
    <p>{cloudEnvDev.skipEmail && <small>No email will be sent</small>}</p>
    <p>
      {cloudEnvDev.skipLob && <small>Lob request will be suppressed</small>}
    </p>
  </div>
)

const Layout = ({ children }) => {
  const [cloudEnvDev, setCloudEnvDev] = useState<CloudEnv>({
    isDevEnv: false,
    skipEmail: false,
    skipLob: false,
  })
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    const checkCloudEnv = async () => {
      const { data } = await apiClient.get<CloudEnv>("helloWorld")
      setCloudEnvDev(data)
    }
    checkCloudEnv()
  }, [])

  return (
    <>
      <div id="layout">
        <div>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
        </div>
        <Footer />
        <div id="dev-overlay">
          {isDevEnv && <IsDevEnv />}
          {cloudEnvDev.isDevEnv && <IsCloudDevEnv cloudEnvDev={cloudEnvDev} />}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
