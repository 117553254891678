export const steps = [
  "Select postcard image",
  "Select your Reps",
  "Write your message",
  "Send",
]

export const CLOUD_FUNCTION_URL = {
  development: `http://localhost:5001/cc-v2-26ab1/us-central1/`,
  production: `https://us-central1-cc-v2-26ab1.cloudfunctions.net/`,
}

export const DOMAIN_URL = "https://congress.cards"
