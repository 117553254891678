import React, { ReactElement } from "react"
import { Link } from "gatsby"

export default function footer(): ReactElement {
  return (
    <footer id="footer">
      <div>
        <ul className="about">
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/tou">Terms of Use</Link>
          </li>
        </ul>
        <ul className="social">
          <li>
            <a
              href="https://www.facebook.com/congress.cards"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.twitter.com/congresscards"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/congress.cards/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
        </ul>
        <div className="printer">
          <img
            src="https://www.nordicenergy.org/wp-content/uploads/2016/03/NegativeCO2_Icon_Colour.png"
            alt="negative CO2 output"
          ></img>
          <p>
            Our printer uses FSC-certified paper from sustainable forests and
            partners with nonprofits to re-plant trees. They aim to plant
            significantly more trees than are used to mail postcards.
          </p>
        </div>
      </div>
      <div>
        <p className="mono">
          Copyright {new Date().getFullYear()} Ⓒ congress.cards
        </p>
      </div>
    </footer>
  )
}
