import sortBy from "lodash/sortBy"
import axios from "axios"
import { fragment } from "./graphql"
import find from "lodash/find"
import { Address, SelectionState } from "../types"
import { CLOUD_FUNCTION_URL } from "../constants"
import { reportError, reportMessage } from "./sentry"

interface Node {
  [key: string]: any
}

interface Edges {
  edges: Node[]
}

interface GraphQLData {
  [key: string]: Edges
}

interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

interface GooglePlacesResult {
  address_components: AddressComponent[]
  formatted_address: string
  geometry: {
    [key: string]: any
  }
  place_id: string
  types: string[]
}

export const sanitize = (key: string, data: GraphQLData) => {
  const nodes = data[key].edges.map(e => e.node)
  return nodes
}

export function byPriority<T>(arr: T[]): T[] {
  return sortBy(arr, ["priority"]).reverse()
}

const env = process.env.NODE_ENV as string

export const isDevEnv: boolean =
  env === "development" || process.env.GATSBY_IS_DEV_ENV === "true"

export const baseUrl = CLOUD_FUNCTION_URL[env]

export const apiClient = axios.create({
  baseURL: baseUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
})

export const validateAddress = (
  address: GooglePlacesResult[]
): Address | null => {
  const a = address[0].address_components
  const getProperty = type => {
    let res = find(a, o => o.types.includes(type))
    return (res && res.short_name) || ""
  }

  const getTown = () => {
    let town = getProperty("locality")
    if (!town) {
      town = getProperty("sublocality")
    }
    if (!town) {
      town = getProperty("administrative_area_level_3")
    }
    return town
  }
  const newAddress: Address = {
    line1: `${getProperty("street_number")} ${getProperty("route")}`,
    city: getTown(),
    state: getProperty("administrative_area_level_1"),
    zip: getProperty("postal_code"),
  }
  if (
    newAddress.line1.trim() &&
    newAddress.city &&
    newAddress.state?.length === 2 &&
    newAddress.zip
  ) {
    return newAddress
  }
  return null
}

// removes fluid images from object prior to sending as payload to cloud functions
// DEPRECATED: removes campaignImg from selectionState even though I clone it
export const removeFluidFromSelection = (
  selection: SelectionState
): Partial<SelectionState> => {
  const clone = { ...selection }
  delete clone.campaign?.campaignImg
  delete clone.campaign?.postcardImg
  return clone
}

export { fragment, reportError, reportMessage }
