import { Link } from "gatsby"
import React from "react"
// @ts-ignore
import logo from "../images/congress-transparent.png"

const Header = ({ siteTitle = `` }: { siteTitle: string }) => (
  <header>
    <div className="container">
      <Link to="/">
        <img src={logo} alt="congress-cards-logo" />
      </Link>
    </div>
    <div className="striped-bar"></div>
  </header>
)

export default Header
